
  import Vue from 'vue'
  import VueRouter from 'vue-router'
  import Menu from '@/components/space/Menu.vue'

  Vue.use(VueRouter)

  export default Vue.extend({
    name: 'SpaceAdministration',
    components: {
      Menu,
    },
    data:() => ({
      tab: null as string | null
    }),
    computed: {
      tabTitle() {
        return this.$vuetify.lang.t("$vuetify.space_admin." + this.tab + ".title")
      }
    },
    methods: {
      onChange(tab: string) {
        this.tab = tab
      }
    },
    watch: {
      '$route'(to) {
        const path = to.path.split('/')
        this.tab = path[path.length - 1]
      }
    }
  })
